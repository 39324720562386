import {
    SERVICE_CATEGORY_CREATED,
    SERVICE_CATEGORY_ERROR,
    SERVICE_CATEGORY_UPDATED,
    SERVICE_CATEGORY_LIST_UPDATED,
    GET_SERVICE_CATEGORY_BY_ID,
    SERVICE_CATEGORY_SEARCH_PARAMATERS_UPDATE,
    CHANGE_SERVICE_CATEGORY_STATUS,
    LOADING_ON_SERVICE_CATEGORY_SUBMIT,
    LOAD_PAGE,
    LOADING_CATEGORY_LIST_DATA,
  } from "actions/types";
  import * as Constants from "constants/index";
  
  const initialState = {
    categoryList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentCategory: [],
    loadingCategoryList: true,
    loadingCategory: true,
    error: {},
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "createdAt",
      ascending: "desc",
      filters: [],
      query: "",
      isAll: 1,
    },
  };
  
  export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SERVICE_CATEGORY_CREATED:
        return {
          ...state,
          loadingCategory: false,
        };
      case LOAD_PAGE:
        return {
          ...state,
          loadingCategory: false,
        };
      case SERVICE_CATEGORY_UPDATED:
        return {
          ...state,
          currentCategory: payload,
          // users: state.user.users.map(user =>
          //   user._id === payload._id ? { payload } : user
          // ),
          sortingParams: initialState.sortingParams,
          loadingCategory: false,
        };
      case SERVICE_CATEGORY_ERROR:
        return {
          ...state,
          error: payload,
          loadingCategory: false,
          loadingCategoryList: false,
        };
      case GET_SERVICE_CATEGORY_BY_ID:
        return {
          ...state,
          currentCategory: payload,
          loadingCategory: false,
        };
      case SERVICE_CATEGORY_LIST_UPDATED:
        return {
          ...state,
          loadingListData: false,
          walkThroughSuccess: false,
          categoryList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord,
          },
          // loadingCategory: true,
          loadingCategoryList: false,
          currentCategory: [],
        };
      case SERVICE_CATEGORY_SEARCH_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload },
        };
      case LOADING_CATEGORY_LIST_DATA:
        return {
          ...state,
          loadingListData: true,
        };
      case CHANGE_SERVICE_CATEGORY_STATUS:
        return {
          ...state,
          usersList: {
            ...state.usersList,
            data: state.usersList.data.map((user) =>
              user._id === payload._id
                ? { ...user, status: payload.status }
                : user
            ),
          },
        };
      case LOADING_ON_SERVICE_CATEGORY_SUBMIT:
        return {
          ...state,
          loadingCategory: true,
        };
      default:
        return state;
    }
  }
  
export const AUTH = {
  SIGNIN: () => ({
    ENDPOINT: `/admin/auth`,
    TYPE: "POST",
  }),
  LOGOUT: () => ({
    ENDPOINT: `/admin/auth/logout`,
    TYPE: "POST",
  }),
  PROFILE: () => ({
    ENDPOINT: `/admin/auth/profile`,
    TYPE: "GET",
  }),
  USER: () => ({
    ENDPOINT: `/admin/auth`,
    TYPE: "GET",
  }),
};

export const PASSWORD = {
  GENERATE_RESET_LINK: () => ({
    ENDPOINT: `/reset-password`,
    TYPE: "POST",
  }),
  VERIFY_RESET_LINK: (token) => ({
    ENDPOINT: `/reset-password/${token}`,
    TYPE: "GET",
  }),
  RESET: (token) => ({
    ENDPOINT: `/reset-password/${token}`,
    TYPE: "PUT",
  }),
};

export const EMAIL = {
  VERIFY: (token) => ({
    ENDPOINT: `/verify/email/${token}`,
    TYPE: "PUT",
  }),
};

export const EMAIL_TEMPLATE = {
  LIST: () => ({
    ENDPOINT: `/admin/emailTemplate`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/emailTemplate/create`,
  }),
};

export const CMS = {
  LIST: () => ({
    ENDPOINT: `/admin/cms`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/cms/create`,
  }),
};

export const BLOGPOST = {
  LIST: () => ({
    ENDPOINT: `/admin/blog/post`,
    TYPE: "GET",
  }),
  // LIST: () => ({
  //   ENDPOINT: `/admin/blog/getCat`,
  //   TYPE: "GET",
  // }),
  CREATE: () => ({
    ENDPOINT: `/admin/blog/post/create`,
  }),
};

export const BLOGCATEGORY={
  LIST: () => ({
    ENDPOINT: `/admin/blog/category`,
    TYPE: "GET",
  }),
  // LIST:()=>({
  //   ENDPOINT: `/admin/blog/category`,
  //   TYPE: "GET",
  // }),
  // CREATE: () => ({
  //   ENDPOINT: `/admin/blog/category/create`,
  //   type:"POST"
  // }),
}

export const BANNER = {
  LIST: () => ({
    ENDPOINT: `/admin/banner`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/banner`,
    TYPE: "POST",
  }),
};

export const PROFILE = {
  UPDATE_PASSWORD: () => ({
    ENDPOINT: `/admin/profile/password`,
    TYPE: "PUT",
  }),
  UPDATE: () => ({
    ENDPOINT: `/admin/profile`,
  }),
  UPDATE_EMAIL: () => ({
    ENDPOINT: `/admin/profile/email`,
    TYPE: "PUT",
  }),
};

export const MACHINE = {
  LIST: () => ({
    ENDPOINT: `/admin/machines`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/machines`,
    TYPE: "POST",
  }),
  UPDATE: (machine_id) => ({
    ENDPOINT: `/admin/machines/${machine_id}`,
    TYPE: "PUT",
  }),
  SEARCH: () => ({
    ENDPOINT: `/admin/machines/search-by-name`,
    TYPE: "GET",
  }),
  CHANGE_CATEGORY: (id) => ({
    ENDPOINT: `/admin/machines/${id}/category`,
    TYPE: "PATCH",
  }),
  DETAILS: (id) => ({
    ENDPOINT: `/admin/machines/${id}`,
    TYPE: "GET",
  }),
  REMOVE_AMI: (id) => ({
    ENDPOINT: `/admin/machines/${id}/remove-ami`,
    TYPE: "PATCH",
  }),
  MACHINE_REQUESTS: () => ({
    ENDPOINT: "/admin/machines/machine-requests",
    TYPE: "GET",
  }),
  CHANGE_REQUEST_STATUS: (id) => ({
    ENDPOINT: `/admin/machines/machine-request/${id}/status`,
    TYPE: "PUT",
  }),
  GET_MACHINE_REQUEST: (id) => ({
    ENDPOINT: `/admin/machines/machine-request/${id}`,
  }),
};

export const CONTACT = {
  LIST: () => ({
    ENDPOINT: `/admin/contact`,
    TYPE: "GET",
  }),
  UPDATE_CONTACT: () => ({
    ENDPOINT: `/admin/contact`,
    TYPE: "PUT",
  }),
  GET_CONTACT: () => ({
    ENDPOINT: `/admin/contact`,
    TYPE: "GET",
  }),
};

export const SERVICE_CATEGORY = {
  LIST: () => ({
    ENDPOINT: `/admin/service/category`,
    TYPE: "GET",
  }),
  UPLOAD_IMAGE: (category_id) => ({
    ENDPOINT: `/admin/service/category/image`,
    TYPE: "POST",
  }),
  DELETE_IMAGE: (category_id, _id) => ({
    ENDPOINT: `/admin/service/category/image`,
    TYPE: "GET",
  }),
};
export const LOCATION = {
  LIST: () => ({
    ENDPOINT: `/admin/service/location`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/service/location`,
  }),
};

export const CARD = {
  LIST: () => ({
    ENDPOINT: `/admin/why-wtf`,
    TYPE: "GET",
  }),
  CARD: (id) => ({
    ENDPOINT: `/admin/why-wtf/${id}`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: "/admin/why-wtf/create",
    TYPE: "POST",
  }),
  DELETE: (id) => ({
    ENDPOINT: `/admin/why-wtf/${id}`,
    TYPE: "DELETE",
  }),
  EDIT: (id) => ({
    ENDPOINT: `/admin/why-wtf/${id}/edit`,
    TYPE: "PUT",
  }),
};

export const SERVICE = {
  LIST: () => ({
    ENDPOINT: `/admin/blog`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/blog`,
  }),
};
export const BUGREPORT = {
  LIST: () => ({
    ENDPOINT: `/admin/reportbug`,
    TYPE: "GET",
  }),
  CHANGE_STATUS: (id) => ({
    ENDPOINT: `/admin/reportbug/${id}/status`,
  }),
};

export const USER = {
  SEARCH: () => ({
    ENDPOINT: `/admin/users/search-by-username`,
    TYPE: "GET",
  }),
};

export const USERS = {
  LIST: () => ({
    ENDPOINT: "/admin/users",
    TYPE: "GET",
  }),
  CHANGE_STATUS: (id) => ({
    ENDPOINT: `/admin/users/${id}/status`,
    TYPE: "PUT",
  }),
  GET_SERVICES: (id) => ({
    ENDPOINT: `/admin/users/${id}/challenges`,
    TYPE: "GET",
  }),
  GET_MACHINES: (id) => ({
    ENDPOINT: `/admin/users/${id}/machines`,
    TYPE: "GET",
  }),
};
export const SETTINGS = {
  LIST: () => ({
    ENDPOINT: "/admin/setting",
    TYPE: "GET",
  }),
  UPDATE_SETTING: (id) => ({
    ENDPOINT: `/admin/setting/${id}`,
    TYPE: "PUT",
  }),
};
export const ABOUTUS = {
  LIST: () => ({
    ENDPOINT: "/admin/aboutUs",
    TYPE: "GET",
  }),
  UPDATE_ABOUTUS: (id) => ({
    ENDPOINT: `/admin/aboutUs/${id}`,
    TYPE: "PUT",
  }),
};

export const SUBBANNER = {
  LIST: () => ({
    ENDPOINT: "/admin/subBanner",
    TYPE: "GET",
  }),
  UPDATE_SUBBANNER: (id) => ({
    ENDPOINT: `/admin/subBanner/${id}`,
    TYPE: "PUT",
  }),
};

export const PACKAGE = {
  LIST: () => ({
    ENDPOINT: `/admin/package`,
    TYPE: "GET",
  }),
  CREATE: () => ({
    ENDPOINT: `/admin/package`,
    TYPE: "POST",
  }),
};

export const DASHBOARD = {
  DETAILS: () => ({
    ENDPOINT: "/admin/dashboard-details",
    TYPE: "GET",
  }),
};

export const OUR_PROPERTIES = {
  LIST: () => ({
    ENDPOINT: "/admin/subBanner",
    TYPE: "GET",
  }),
  UPDATE_OUR_PROPERTIES: () => ({
    ENDPOINT: `/admin/our-properties`,
    TYPE: "PUT",
  }),
  GET_OUR_PROPERTIES: () => ({
    ENDPOINT: `/admin/our-properties`,
    TYPE: "GET",
  }),
};

export const AMENITIES = {
  UPDATE_AMENITIES: () => ({
    ENDPOINT: `/admin/amenities`,
    TYPE: "PUT",
  }),
  GET_AMENITIES: () => ({
    ENDPOINT: `/admin/amenities`,
    TYPE: "GET",
  }),
};

export const AVAILABILITY = {
  UPDATE_AVAILABILITY: () => ({
    ENDPOINT: `/admin/availability`,
    TYPE: "PUT",
  }),
  GET_AVAILABILITY: () => ({
    ENDPOINT: `/admin/availability`,
    TYPE: "GET",
  }),
};

export const GALLERY = {
  UPDATE_GALLERY: () => ({
    ENDPOINT: `/admin/gallery`,
    TYPE: "PUT",
  }),
  GET_GALLERY: () => ({
    ENDPOINT: `/admin/gallery`,
    TYPE: "GET",
  }),
};


export const HEADER = {
  UPDATE_HEADER: () => ({
    ENDPOINT: `/admin/header`,
    TYPE: "PUT",
  }),
  GET_HEADER: () => ({
    ENDPOINT: `/admin/header`,
    TYPE: "GET",
  }),
};


export const THEMECOLOR = {
  UPDATE_THEMECOLOR: () => ({
    ENDPOINT: `/admin/theme-color`,
    TYPE: "PUT",
  }),
  GET_THEMECOLOR: () => ({
    ENDPOINT: `/admin/theme-color`,
    TYPE: "GET",
  }),
};
export const ENQUIRY = {
  LIST: () => ({
    ENDPOINT: `/admin/enquiry`,
    TYPE: "GET",
  }),
  CSV: () => ({
    ENDPOINT: "/admin/enquiry/csv",
    TYPE: "GET",
  }),
};

export const SUBSCRIBER = {
  LIST: () => ({
    ENDPOINT: "/admin/subscriber",
    TYPE: "GET",
  }),
  CSV: () => ({
    ENDPOINT: "/admin/subscriber/csv",
    TYPE: "GET",
  }),
};

import { combineReducers } from "redux";
import category from "./admin/category";
import alert from "./alert";
import auth from "./auth";
import errors from "./errors";
import profile from "./admin/profile";
import sidebar from "./admin/sidebar";
import notification from "./admin/notification";
import verification from "./verification";
import cms from "./admin/cms";
import blog from "./admin/blogPost";
import categorys from "./admin/blogCategory";
import contact from "./admin/contact";
import setting from "./admin/setting";
import location from "./admin/location";
import service from "./admin/service";
import aboutUs from "./admin/aboutUs";
import banner from "./admin/banner";
import subBanner from "./admin/subBanner";
import packages from "./admin/packages";
import whyWall2floor from "./admin/whyWall2floor";
import metadata from "./admin/metadata";
import dashboard from "./admin/dashboard";
import ourProperties from "./admin/ourProperties";
import amenities from "./admin/amenities";
import availability from "./admin/availability";
import gallery from "./admin/gallery";
import header from "./admin/header";
import properties from "./admin/properties";
import themeColor from "./admin/themeColor";
import enquiry from "./admin/enquiry";
import subscribers from "./admin/subscribers";
const appReducer = combineReducers({
  alert,
  auth,
  errors,
  notification,
  profile,
  sidebar,
  verification,
  categorys,
  cms,
  blog,
  category,
  contact,
  location,
  service,
  setting,
  aboutUs,
  banner,
  subBanner,
  packages,
  whyWall2floor,
  metadata,
  dashboard,
  ourProperties,
  properties,
  amenities,
  availability,
  gallery,
  header,
  themeColor,
  enquiry,
  subscribers,
});

const rootReducer = (state, action_taken) => {
  return appReducer(state, action_taken);
};

export default rootReducer;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Select from "react-select";
import { CATEGORY_STATUS } from "constants";
import {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
  getCategoryAndAuthorList,
} from "actions/admin/blogPost";
import Errors from "views/components/Notifications/Errors";
import Spinner from "views/components/Spinner";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,


  Row,
} from "reactstrap";
import { useNavigate } from "react-router";
import { CATEGORY } from "constants";

const CreateBLOG = ({
  create,
  cancelSave,
  errorList,
  loading,
  setErrors,
  loadPage,
  removeErrors,
  getCategoryAndAuthorList,
  category_auth_list
}) => {
  // console.log(category_auth_list, "category_auth_list")
  const history = useNavigate();
  useEffect(() => {
    getCategoryAndAuthorList();
    
  }, [])

  const [option, setOption] = useState([])

  useEffect(() => {
    if (category_auth_list.length > 0) {
      const itemOptions = [];
      category_auth_list.map(item => {
        itemOptions.push({ value: item._id, label: item.name })
      })
      setOption(itemOptions);
    }
  }, [category_auth_list])


  const [formData, setFormData] = useState({
    visible_in_footer: 0,
    selectedCategory: {},
    // name: "",
    title: "",
    author: "",
    status: 1,
    is_published: true,
   
    description: "",
    category: [],
    
    thumbnail: "",
    selectedFile: "",
    image: "",
  });

  const {
  
    title,
    author,
    status,
    is_published,
    // published,
    // slug,
    category,
    description,

    
    image,
  } = formData;



  const onChange = (e) => {
    switch (e.target.name) {
      case "title":
        const inputText = e.target.value;
        if (inputText.trim() === '') {
          // If input contains only whitespace, set title to an empty string
          setFormData({ ...formData, [e.target.name]: "" })
        } else {
          // Otherwise, update the title state with the input value
          setFormData({ ...formData, [e.target.name]: inputText })
        }
        break;
      case "author":
        const inputAuthor = e.target.value;
        if (inputAuthor.trim() === '') {
          // If input contains only whitespace, set title to an empty string
          setFormData({ ...formData, [e.target.name]: "" })
        } else {
          // Otherwise, update the title state with the input value
          setFormData({ ...formData, [e.target.name]: inputAuthor })
        }
        break;
      case "is_published":
        console.log(e.target.checked)
        console.log(e.target.name, "react")

        setFormData({ ...formData, [e.target.name]: e.target.checked })
        break;
      case "thumbnail":
        // console.log(e.target.files,"filesssssss")
        let image_file = e.target.files[0];

        let image_url = URL.createObjectURL(image_file);

        setFormData({
          ...formData,
          [e.target.name]: image_file,
          selectedFile: image_file,
           image: image_url,
        });
        break;
      default:

        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData, "formData");
    }
  };

 

 
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  /**
   * 1) IF form field is an object
   *    --- [{
   *          "param":"field_name",
   *          "value":"value that has to be matched from object",
   *          "actualParam":"to represent actual field name"
   *          "msg": "msg that has to be shown"
   *        }]
   * 2) IF form field is string
   *    --- [{
   *          "param":"field_name",
   *          "actualParam":"to represent actual field name",
   *          "msg": "msg that has to be shown"
   *        }]
   * @param {object} form , data which has to be verify
   * @param {array} requireFields , fields that has to be verify
   */
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      }

     
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    // console.log(formData);
    if (
      !validateForm(formData, [
      
        {
          param: "title",
          msg: "Please enter the header of page",
        },
        {
          param: "author",
          msg: "Please enter the author name",
        },
     
        {
          param: "category",
          msg: "Please enter the category of page",
        },
        {
          param: "image",
          actualParam: "thumbnail",
          msg: "Please select a page thumbnail",
        },
        {
          param: "description",
          msg: "Please provide the page description",
        },
       
      ])
    )
      return;

    const submitData = {};
    const excludeList = { thumbnail: 1 };
    for (let i in formData) {
      // console.log(,"iiiiiiiiiiiiiiiiiiiiii")
      if (excludeList[i]) continue;
      submitData[i] = formData[i];
      // console.log(submitData[i]);
    }
    submitData.category = JSON.stringify(submitData.category)
    console.log(history,"historyyyyyyyyy")
    create(submitData, history);
  };


  const addPost = (value) => {

    // console.log("what "+CATEGORY_STATUS);
    setFormData({ ...formData, status: value.value });
  };
  
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
    // history("/dashboard/blog/post")
  };

  const handleCategoryAdd = (item) => {
    console.log(item, "llllllllllllllllll")
    setFormData({ ...formData, category: item });
  };
 
  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // })
  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Blog Information</h5>
              </CardHeader>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
              
                  <FormGroup>
                    <Label htmlFor="category">
                      Category <span>*</span>
                    </Label>


                    <Select
                      name="category"
                      required
                      options=
                      {option}
                      isMulti
                      value={formData.category}
                      //  value={selectedCategory}
                      onChange={handleCategoryAdd}
                    // onChange={(e) => onChange(e)}
                    >



                    </Select>
                    <Errors current_key="category" key="category" />
                  </FormGroup>
               
                  <FormGroup>
                    <Label htmlFor="title">
                      Title <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      maxLength="100"
                      value={title}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.title ? true : false}
                    />
                    <Errors current_key="title" key="title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="author">
                      Author <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="author"
                      name="author"
                      maxLength="100"
                      value={author}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.author ? true : false}
                    />
                    <Errors current_key="author" key="author" />
                  </FormGroup>
                
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="status">
                          Status <span>*</span>
                        </Label>
                        <Select
                          options={CATEGORY_STATUS}
                          value={CATEGORY_STATUS.find(
                            (cat) => cat.value === status
                          )}
                          onChange={addPost}
                          isClearable={false}
                        />
                        <Errors current_key="status" key="status" />
                      </FormGroup>

                    </Col>
                    <Col xs="2"></Col>

                    <Col xs="4">
                      <FormGroup>
                        <Row>

                          <Label htmlFor="is_published">Published*</Label>
                        </Row>
                        <input type="checkbox"

                          name="is_published"
                          checked={formData.is_published}
                          onChange={(e) => onChange(e)}
                          // value={formData.is_published}
                          id="is_published"
                          style={{ transform: "scale(2)", marginRight: "8px" }}
                        />
                     

                      </FormGroup>
                      <FormGroup>


                      </FormGroup>


                    </Col>


                  </Row>
                  <FormGroup>
                    <Label htmlFor="thumbnail">
                      Thumbnail<span>*</span>
                    </Label>
                    <Input
                      type="file"
                      required
                      id="thumbnail"
                      name="thumbnail"
                      onChange={(e) => onChange(e)}
                      // multiple
                      accept="image/png, image/jpeg"
                      invalid={errorList.thumbnail ? true : false}
                    />
                    {image ? (
                      <img
                        src={image}
                        style={{ padding: 10, height: "150px", width: "150px" }}
                        className="preview-img img-fluid"
                        alt=""
                      />
                    ) : null}
                    {/* {image_preview} */}
                    <Errors current_key="thumbnail" key="thumbnail" />
                  </FormGroup>
                 

                  <FormGroup>
                    <Label htmlFor="description">
                      Body <span>*</span>
                    </Label>
                    
                    <CKEditor
                      editor={Editor}
                      required
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        onChangeDescription(data);
                      }}
                    />
                    <Errors current_key="description" key="description" />
                  </FormGroup>
                
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    {/* {loading?<Spinner/>: */}
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateBLOG.propTypes = {
  create: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  blog: PropTypes.object,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadPage: PropTypes.func.isRequired,
  getCategoryAndAuthorList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  blog: state.blog,
  category_auth_list: state.blog.category_auth_list,
  errorList: state.errors,
  //  loading: state.blog.loadingEmail,
  loading: state.blog.loadingBLOG
});

export default connect(mapStateToProps, {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
  getCategoryAndAuthorList
})(CreateBLOG);
import {
  BLOG_CREATED,
  BLOG_ERROR,
  DELETE_BLOG,
  BLOG_UPDATED,
  BLOG_LIST_UPDATED,
  GET_BLOG_BY_ID,
  LOADING_ON_EMAIL_SUBMIT,
  BLOG_SEARCH_PARAMATERS_UPDATE,
  CHANGE_BLOG_STATUS,
  LOAD_PAGE,
  LOADING_EMAIL_LIST_DATA,
  RESET,
  GET_CATEGORY_AUTHOR_LIST,
  
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  blogList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentBLOG: [],
  loadingBLOGList: true,
  loadingBLOG: true,
  loadingListData: true,
  performDelete: false,
  category_auth_list : [],
  error: {},
  sortingParams: {
    limit: 30,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: {},
    filters: [],
  },
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingBLOG: state.loadingBLOG,
        loadingBLOGList: state.loadingBLOGList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingBLOG: false,
      };
    case BLOG_CREATED:
      return {
        ...state,
        loadingBLOG: false,
      };
    case BLOG_UPDATED:
      return {
        ...state,
        currentBLOG: payload,
        sortingParams: initialState.sortingParams,
        loadingBLOG: false,
      };
    case BLOG_ERROR:
      return {
        ...state,
        error: payload,
        loadingBLOG: false,
        loadingBLOGList: false,
        loadingListData: false,
        performDelete: false,
      };
    case DELETE_BLOG:
      const currentCount = state.blogList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.blogList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        blogList: {
          data: state.blogList.data.filter((blog) => blog._id !== payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingBLOGList: false,
        performDelete: true,
      };
    case GET_BLOG_BY_ID:
      return {
        ...state,
        currentBLOG: payload,
        loadingBLOG: false,
      };
    case BLOG_LIST_UPDATED:
      return {
        ...state,
        blogList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingBLOG: true,
        loadingBLOGList: false,
        loadingListData: false,
        performDelete: false,
      };
    case BLOG_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };

    case CHANGE_BLOG_STATUS:
      return {
        ...state,
        blogList: {
          ...state.blogList,
          data: state.blogList.data.map((blog) =>
            blog._id === payload._id
              ? { ...blog, status: payload.status }
              : blog
          ),
        },
      };
    case LOADING_EMAIL_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_EMAIL_SUBMIT:
      return {
        ...state,
        loadingBLOG: true,
      };
    case GET_CATEGORY_AUTHOR_LIST:
      return{
        ...state ,
        category_auth_list: payload,
      }

    default:
      return state;
  }
}

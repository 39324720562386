import React, { useState, useEffect, useMemo } from "react";
import PropTypes, { string } from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import debounce from "lodash.debounce"
import Editor from "ckeditor5-custom-build/build/ckeditor";
import queryString from 'query-string';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  updateHeader,
  getHeader,
  removeErrors,
  setErrors,
  loadingHeader,
} from "actions/admin/header";
import { FaPlusSquare, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import Spinner from "views/components/Spinner";

// let addTourImage = [];
// let addGalleryImage = [];

let images = [];
let imageUrls = [];
let tourImages = [];
let tourImageUrls = [];

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'contents',
  padding: grid,
  overflow: 'auto',
});


const Header = ({
  updateHeader,
  getHeader,
  loading,
  removeErrors,
  setErrors,
}) => {
  const params = useParams();
  const history = useNavigate();

  const floorsFields = {
    label: "",
    button_text: "",
    image: "",
    image_url: "",
    description: "",
    sub_description: "",
  };
  const threeDImageFields = {
    threeDlink: ""
  };
  const initialState = {
    // floor_heading:"",
    student_application: "",
    student_application_url: "",
    professional_application: "",
    professional_application_url: "",
    tenant_login: "",
    tenant_login_url: "",
    book_appointment: "",
    book_appointment_url: "",

    // professional_application:"",

  };
  const [formData, setFormData] = useState(initialState);
  const [threeDImage, setThreeDImage] = useState([threeDImageFields]);

  const [floorsData, setFloorsData] = useState([floorsFields]);
  const [onlyOnce, setOnce] = useState(false);
  const [queryStringData, setQueryStringData] = useState("");
  const [dragDrop, setDragDrop] = useState(imageUrls);
  const [isPrev, setIsPrev] = useState(false);

  // const { heading, subHeading } = formData;

  const onChange = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [event.target.name]: value.replace(/[^a-z0-9]/gi, "")
        ? value.trimLeft()
        : value.trim(),
    });
  };


  useEffect(() => {
    const queryData = {
      // floor_background_color: formData.floor_background_color,
      // floor_box_color: formData.floor_box_color,

      // floor_title_color: formData.floor_title_color,
      // floor_content_color: formData.floor_content_color,

      // banner_color: formData.banner_color,
      // sub_heading_color: formData.sub_heading_color,
    }
    const setString = queryString.stringify({ prev: JSON.stringify(queryData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/header?${setString}`)
  }, [formData])

  useEffect(async () => {
    const getData = await getHeader();
    if (getData) {
      searchHandler();
      setFormData({
        ...formData,
        // floor_heading:getData.floor_heading,
        student_application: getData.student_application,
        student_application_url: getData.student_application_url,
        professional_application: getData.professional_application,
        professional_application_url: getData.professional_application_url,
        tenant_login: getData.tenant_login,
        tenant_login_url: getData.tenant_login_url,
        book_appointment: getData.book_appointment,
        book_appointment_url: getData.book_appointment_url,


        // professional_application: getData.professional_application,
      });
    }
  }, []);

  const searchHandler = useMemo(() => {
    return debounce(() => {
      setOnce(true)
    }, 1000)
  }, [])



  const onSubmit = (e) => {
    e.preventDefault();
    updateHeader(formData, history, "");
  };


  return (
    <div className="animated fadeIn">
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Header Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>

              <Card>

                <CardBody>
                  <h6>
                    <b>Apply Online Button  </b>
                  </h6>
                  <Row>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="student_application">
                          Student Application <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="student_application"
                          name="student_application"
                          value={formData.student_application}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="student_application"
                          key="student_application"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="student_application_url">
                          Student Application Url <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="student_application_url"
                          name="student_application_url"
                          value={formData.student_application_url}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="student_application_url"
                          key="student_application_url"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="professional_application">
                          Professional Application <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="professional_application"
                          name="professional_application"
                          value={formData.professional_application}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_description ? true : false}
                        />
                        <Errors
                          current_key="professional_application"
                          key="professional_application"
                        />
                      </FormGroup>
                    </Col>


                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="professional_application_url">
                          Professional Application Url <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="professional_application_url"
                          name="professional_application_url"
                          value={formData.professional_application_url}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="professional_application_url"
                          key="professional_application_url"
                        />
                      </FormGroup>
                    </Col>

                    <hr />
                    <h6>
                      <b>Tenant Login Button </b>
                    </h6>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="tenant_login">
                          Tenant Login  <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="tenant_login"
                          name="tenant_login"
                          value={formData.tenant_login}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="tenant_login"
                          key="tenant_login"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="tenant_login_url">
                          Tenant Login Url  <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="tenant_login_url"
                          name="tenant_login_url"
                          value={formData.tenant_login_url}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="tenant_login_url"
                          key="tenant_login_url"
                        />
                      </FormGroup>
                    </Col>
                    <hr />
                    <h6>
                      <b>Book Appointment </b>
                    </h6>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="book_appointment">
                          Book Appointment  <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="book_appointment"
                          name="book_appointment"
                          value={formData.book_appointment}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="book_appointment"
                          key="book_appointment"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="book_appointment_url">
                          Book Appointment Url  <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="book_appointment_url"
                          name="book_appointment_url"
                          value={formData.book_appointment_url}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="book_appointment_url"
                          key="book_appointment_url"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    style={{ width: "60px" }}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard/cms">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateHeader: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  //currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.header.loadingHeader,
});

export default connect(mapStateToProps, {
  updateHeader,
  getHeader,
  removeErrors,
  setErrors,
})(Header);

import {
    HEADER_ERROR,
    HEADER_UPDATED,
    LOADING_ON_HEADER_SUBMIT,
    LOADING_SUBBANNER_DATA,
    GET_SUBBANNER,
    LOAD_HEADER,
    LOADING_HEADER,
    GET_HEADER
} from "actions/types";

const initialState = {
    currentHeader: null,
    loadingHeader: false,
    error: {},
};

export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case HEADER_ERROR:
            return {
                ...state,
                error: payload,
                loadingHeader: false,
            };
        case GET_SUBBANNER:
            return {
                ...state,
                currentHeader: payload,
                loadingHeader: false,
            };
        case LOAD_HEADER:
            return {
                ...state,
                loadingHeader: false,
            };
        case LOADING_HEADER:
        case LOADING_ON_HEADER_SUBMIT:
            return {
                ...state,
                loadingHeader: true,
            };
        case GET_HEADER:
        case HEADER_UPDATED:
            return {
                ...state,
                loadingHeader: false,
            }
        default:
            return state;
    }
}

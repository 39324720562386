import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  CATEGORY_CREATED,
  CATEGORY_ERROR,
  CATEGORY_LIST_UPDATED,
  GET_CATEGORY_BY_ID,
  CATEGORY_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  //INITIAL_LOADING,
  LOADING_ON_CATEGORY_SUBMIT,
  LOAD_PAGE,
  LOADING_CATEGORYS_LIST_DATA,
  RESET
} from "actions/types";
import { BLOGCATEGORY } from "constants/api";
import { logout } from "../auth";

//get users list
export const getCategoryList = (userParams = {}, noRedux) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    !noRedux && dispatch({ type: REMOVE_ERRORS });
    !noRedux && dispatch({ type: LOADING_CATEGORYS_LIST_DATA });
    const query = userParams.query ? userParams.query : "";
    userParams.query = query;
    config.params = userParams;
    // const query = userParams.query ? userParams.query : "";
    // userParams.query = query;
    // config.params = userParams;
    const res = await axios.get(`${BLOGCATEGORY.LIST().ENDPOINT}`, config);
    // console.log(BLOGCATEGORY.LIST().ENDPOINT)

    !noRedux && dispatch({
      type: CATEGORY_SEARCH_PARAMATERS_UPDATE,
      payload: userParams,
    });
    !noRedux && dispatch({
      type: CATEGORY_LIST_UPDATED,
      payload: res.data.response[0],
    });
    console.log(res.data.response[0]);

    return res.data.response[0];
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: CATEGORY_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};
// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: CATEGORY_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
// Dispatch Loading
export const loadingOnCategorySubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_CATEGORY_SUBMIT });
};

// Create Category
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(BLOGCATEGORY.LIST().ENDPOINT, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: CATEGORY_CREATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert("Category created", "success")
      );
      history("/dashboard/blog/category");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: CATEGORY_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Update Category
export const updateCategory = (formData, history, _id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`${BLOGCATEGORY.LIST().ENDPOINT}/${_id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: CATEGORY_CREATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert("Category updated successfully", "success")
      );
      history("/dashboard/blog/category");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: CATEGORY_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete Category
export const deleteCategory = (_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.delete(`${BLOGCATEGORY.LIST().ENDPOINT}/${_id}`, config);
    if (res.data.status === true) {
      dispatch(
        setAlert("Category deleted successfully", "success")
      );
      dispatch(getCategoryList())
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// get Category by id
export const getCategoryById = (category_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${BLOGCATEGORY.LIST().ENDPOINT}/${category_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_CATEGORY_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: CATEGORY_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  history("/dashboard/blog/category");
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/admin/page-not-found");
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CATEGORY_STATUS } from "constants";
import { connect } from "react-redux";
import {
  edit,
  cancelSave,
  getBLOGById,
  notFound,
  setErrors,
  removeErrors,
  resetComponentStore,
  getCategoryAndAuthorList,
} from "actions/admin/blogPost";
import Spinner from "views/components/Spinner";
// import { create as createCategory, getBlogCategoryList } from "actions/admin/pageCategory";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CATEGORY } from "constants";

const EditBLOG = ({
  getBLOGById,
  edit,
  cancelSave,
  notFound,
  currentBLOG,
  loading,
  errorList,
  setErrors,
  removeErrors,
  resetComponentStore,
  getCategoryAndAuthorList,
  category_auth_list
}) => {

  const params = useParams();
  const history = useNavigate();
  const initialState = {
    category: "",
    visible_in_footer: 0,
    selectedCategory: {},
    // name: "",
    title: "",
    author: "",
    category: [],
    // slug: "",
    // short_description: "",
    description: "",
    status: 1,
    is_published: true,
    // meta_description: "",
    thumbnail: "",
    // selectedFile:"",
    imageURL: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);
  useEffect(() => {
    getCategoryAndAuthorList();
  }, [])
  const onChange = (e) => {
    switch (e.target.name) {

      case "title":
        const inputText = e.target.value;
        if (inputText.trim() === '') {
          // If input contains only whitespace, set title to an empty string
          setFormData({ ...formData, [e.target.name]: "" })
        } else {
          // Otherwise, update the title state with the input value
          setFormData({ ...formData, [e.target.name]: inputText })
        }
        break;
      case "author":
          const inputAuthor = e.target.value;
          if (inputAuthor.trim() === '') {
            // If input contains only whitespace, set title to an empty string
            setFormData({ ...formData, [e.target.name]: "" })
          } else {
            // Otherwise, update the title state with the input value
            setFormData({ ...formData, [e.target.name]: inputAuthor })
          }
          break;
      case "thumbnail":
        let image_file = e.target.files[0];
        let image_url = URL.createObjectURL(image_file);
        setFormData({
          ...formData,
          [e.target.name]: image_file,
          // selectedFile: image_file,
          imageURL: image_url,
        });
        break;

      case "is_published":

        //e.target.value = e.target.checked;

        // console.log(e.target.value)
        setFormData({ ...formData, [e.target.name]: e.target.checked })
        break
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // useEffect(() => {
  //   console.log(currentBLOG, "llllllllllllllllllllllll")
  // })

  const [option, setOption] = useState([])

  useEffect(() => {
    if (category_auth_list.length > 0) {
      const itemOptions = [];
      category_auth_list.map(item => {
        itemOptions.push({ value: item._id, label: item.name })
      })
      setOption(itemOptions);
    }
  }, [category_auth_list])



  // const handleDescriptionChange = (event /* editor */) => {
  //   const data = event.editor.getData(); // editor.getData();
  //   setFormData({ ...formData, description: data });
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  /**
   * 1) IF form field is an object
   *    --- [{
   *          "param":"field_name",
   *          "value":"value that has to be matched from object",
   *          "actualParam":"to represent actual field name"
   *          "msg": "msg that has to be shown"
   *        }]
   * 2) IF form field is string
   *    --- [{
   *          "param":"field_name",
   *          "actualParam":"to represent actual field name",
   *          "msg": "msg that has to be shown"
   *        }]
   * @param {object} form , data which has to be verify
   * @param {array} requireFields , fields that has to be verify
   */
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      }

     
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
       
        {
          param: "category",
          msg: "Please enter the category of page",
        },
        {
          param: "title",
          msg: "Please enter the header of page",
        },
        {
          param: "author",
          msg: "Please enter the author name",
        },
        {
          param: "imageURL",
          actualParam: "thumbnail",
          msg: "Please select a page thumbnail",
        },
        {
          param: "description",
          msg: "Please provide the page description",
        },
        // {
        //   param: "short_description",
        //   msg: "Please provide the page short description",
        // },
      ])
    )
      return;
    //  let image= URL.createObjectURL(imageURL)
    // console.log(formData,"betweeeeeeeeeee")
    const submitData = {};
    //  const excludeList = { imageURL: 1 };
    for (let i in formData) {
      // if ((formData[i] !== 0 && !formData[i])) continue;
      submitData[i] = formData[i];
    }
    console.log(submitData, "llllllllllllllllll")
    submitData.category = JSON.stringify(submitData.category)
    edit(submitData, history, params.blog_id);
  };

  const addCategory = (value) => {
    console.log(value);
    setFormData({ ...formData, status: value.value });
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
    // history('/dashboard/blog/post')

  };
  const handleCategoryAdd = (item) => {
    setFormData({ ...formData, category: item });
  };

  useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    getBLOGById(params.blog_id).then((res) => {
      if (res === undefined) notFound(history);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.blog_id, history]);



  useEffect(() => {
    console.log(currentBLOG, "currentttttttttttttt")
  })


  useEffect(async () => {
    if (currentBLOG) {
      const {
        // name,
        title,
        author,
        status,
        is_published,
        description,
        // status,
        imageFile,
        //  thumbnail,
      } = currentBLOG;

  
   
      let itemOptionss = await currentBLOG?.category?.map(item => {
        const matchedCategory = category_auth_list.find(
          (name) => name._id === item.category_id
        );
    
        if (matchedCategory) {
          // If a matching category is found, update the item name
          item.name = matchedCategory.name;
        }
        return { value: item.category_id, label: item.name }
      })
      

     
      setFormData((form) => ({
        ...form,
       
        title,
        author,
        status,
        is_published,
        category: itemOptionss,
        
        description,
        thumbnail: imageFile?.path,
        imageURL: imageFile?.fullURL,
      }));
    }

  }, [currentBLOG]);

  const {
    category,
    title,
    author,
    status,
    is_published,
    // slug,
    // short_description,
    description,

    // meta_description,
    thumbnail,
    imageURL,
  } = formData;
  const selectedCategory = CATEGORY.filter((itm) => itm.value === category)[0];

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="6" md="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Blog Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>

                <FormGroup>
                  <Label htmlFor="category">
                    Category <span>*</span>
                  </Label>
                  <Select
                    name="category"
                    options=
                    {option}
                    isMulti
                    value={formData.category && formData.category}
                    //  value={selectedCategory}
                    onChange={handleCategoryAdd}
                  // onChange={(e) => onChange(e)}
                  >


                  </Select>
                  <Errors current_key="category" key="category" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="title">
                    Title <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    maxLength="100"
                    value={title}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.title ? true : false}
                  />
                  <Errors current_key="title" key="title" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="author">
                    Author <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="author"
                    name="author"
                    maxLength="100"
                    value={author}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.author ? true : false}
                  />
                  <Errors current_key="author" key="author" />
                </FormGroup>
                <Row>

                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="status">
                        Status <span>*</span>
                      </Label>
                      <Select
                        options={CATEGORY_STATUS}
                        value={CATEGORY_STATUS.find(
                          (cat) => cat.value === status
                        )}
                        onChange={addCategory}
                        isClearable={false}
                      />
                      <Errors current_key="status" key="status" />
                    </FormGroup>
                  </Col>

                  <Col xs="4">
                    <FormGroup>
                      <Row>

                        <Label htmlFor="is_published">Published*</Label>
                      </Row>
                      <input type="checkbox"
                        checked={formData.is_published}
                        name="is_published"
                        // checked={formData.is_published}
                        onChange={(e) => onChange(e)}
                        // value={formData.is_published}
                        id="is_published"
                        style={{ transform: "scale(2)", marginRight: "8px" }}
                      />
                      {/* <label htmlFor="">
                        {formData.is_published?"yes":"no"}

                      </label> */}


                    </FormGroup>
                    <FormGroup>


                    </FormGroup>


                  </Col>









                </Row>
                <FormGroup>
                  <Label htmlFor="thumbnail">
                    Thumbnail<span>*</span>
                  </Label>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    onChange={(e) => onChange(e)}
                    multiple
                    invalid={errorList.thumbnail ? true : false}
                    accept="image/png, image/jpeg"
                  />
                  <img
                    src={imageURL}
                    style={{ padding: 10, height: "150px", width: "150px" }}
                    className="preview-img img-fluid"
                    alt=""
                  />
                  <Errors current_key="thumbnail" key="thumbnail" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">
                    Body <span>*</span>
                  </Label>
                  {/**
                   * CKEditor element conflict bug
                   * Fix: onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                   * Issue: https://github.com/ckeditor/ckeditor4-react/issues/57
                   */}

                  

                  <CKEditor
                    editor={Editor}
                    required
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      onChangeDescription(data);
                    }}
                  />

                  <Errors current_key="description" key="description" />
                </FormGroup>




              </CardBody>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditBLOG.propTypes = {
  getBLOGById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  // createCategory: PropTypes.func.isRequired,
  // getBlogCategoryList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cancelSave: PropTypes.func.isRequired,
  notFound: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  getCategoryAndAuthorList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  currentBLOG: state.blog.currentBLOG,
  errorList: state.errors,
  loading: state.blog.loadingBLOG,
  category_auth_list: state.blog.category_auth_list,
  // pageCategoryParams: state.pageCategory.sortingParams,
});

export default connect(mapStateToProps, {
  getBLOGById,
  edit,
  // createCategory,
  // getBlogCategoryList,
  getCategoryAndAuthorList,
  cancelSave,
  notFound,
  setErrors,
  removeErrors,
  resetComponentStore,
})(EditBLOG);